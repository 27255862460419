"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._sendBeacon = _sendBeacon;
exports.setBeaconApi = setBeaconApi;
exports.setStaticAppInfo = setStaticAppInfo;
exports.send = send;

var _enviro = _interopRequireDefault(require("enviro"));

var _hubspot = _interopRequireDefault(require("hubspot"));

var _getGlobal = require("./getGlobal");

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore provided by head-dlb
// need to support the React Native globalThis
var _customSendBeaconFn;

function _sendBeacon(url, data) {
  var global = (0, _getGlobal.getGlobal)();

  if (_customSendBeaconFn) {
    return _customSendBeaconFn(url, data);
  }

  return global.navigator && global.navigator.sendBeacon(url, data);
}

function sendBeacon(url) {
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  if (!_sendBeacon) {
    return;
  }

  try {
    _sendBeacon(url, data);
  } catch (___err) {// drop errors
  }
}

function setBeaconApi(beaconFn) {
  _customSendBeaconFn = beaconFn;
}

var staticAppInfo = {
  package: _hubspot.default && _hubspot.default.bender && _hubspot.default.bender.currentProject || 'unknown',
  version: _hubspot.default && _hubspot.default.bender && _hubspot.default.bender.currentProjectVersion || 'unknown'
};

function setStaticAppInfo(newInfo) {
  Object.assign(staticAppInfo, newInfo);
}

function getMetricsUrl() {
  return "https://api" + (_enviro.default.getHublet() === 'na1' ? '' : "-" + _enviro.default.getHublet()) + ".hubspot" + (_enviro.default.isQa() ? 'qa' : '') + ".com/metrics/v1/frontend/custom/send?hs_static_app=" + staticAppInfo.package + "&hs_static_app_version=" + staticAppInfo.version;
}

function send(metricReports) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore dead code check, this is safe
  if (process.env.NODE_ENV !== 'production') {
    if (_enviro.default.debug('METRICS') && !_enviro.default.deployed('METRICS')) {
      console.log('[metrics-js] Dropping local datapoint', metricReports);
      return;
    }
  }

  if (_enviro.default.debug('METRICS')) {
    console.log('[metrics-js] Datapoint sent', metricReports);
  }

  sendBeacon(getMetricsUrl(), JSON.stringify(metricReports));
}
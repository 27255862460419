'use es6';

import { getCookie } from 'hub-http/helpers/cookies';
import { hstcKey } from 'usage-tracker-core/storageKeys';
let hasCookieAccess = false;

try {
  hasCookieAccess = navigator && navigator.cookieEnabled || document && document.cookie;
} catch (err) {
  /* noop */
}

export const get = key => {
  if (hasCookieAccess) {
    return getCookie(key, document.cookie);
  }

  return null;
};
export const set = (key, value, expiresInMillis = 60 * 24 * 60 * 60 * 1000) => {
  if (hasCookieAccess) {
    const currentTime = new Date();
    const expiryTime = new Date(currentTime.setTime(currentTime.getTime() + expiresInMillis)).toUTCString();
    document.cookie = `${key}=${value};expires=${expiryTime};path=/`;
  }
};
export function getHstc() {
  return get(hstcKey) || null;
}
export function getUtk() {
  let utk = get('hubspotutk');

  if (!utk) {
    utk = getHstc();

    if (utk) {
      utk = utk.split('.')[1];
    }
  }

  return utk || null;
}
'use es6';

import { getMessage } from 'conversations-internal-schema/widget-data/operators/widgetDataGetters';
import { getPopOpenWidget } from 'conversations-internal-schema/message/operators/messageGetters';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { hasClientTriggers } from '../../client-triggers/operators/hasClientTriggers';
export const hasPopOpenWidgetAndClientTriggers = widgetAsyncData => {
  const widgetData = getData(widgetAsyncData);
  const messageData = getMessage(widgetData);
  const popOpenWidget = getPopOpenWidget(messageData);
  const clientTriggers = hasClientTriggers(widgetData);
  return popOpenWidget && clientTriggers;
};
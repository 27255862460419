"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.eu1 = exports.test2 = exports.na1 = void 0;
var na1 = 'na1';
exports.na1 = na1;
var test2 = 'test2';
exports.test2 = test2;
var eu1 = 'eu1';
exports.eu1 = eu1;
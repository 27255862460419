'use es6';

import { Record } from 'immutable';
import { MESSAGE_TYPE_ID, ACTIVELY_PUBLISHING } from '../constants/messageTypes';
const ActivelyPublishing = Record({
  [MESSAGE_TYPE_ID]: ACTIVELY_PUBLISHING,
  threadId: null,
  channel: null,
  message: null
}, 'ActivelyPublishing');
export default ActivelyPublishing;
'use es6';

import PublicTrackingClient from './PublicTrackingClient';
const DEFAULT_OPTS = {
  allowUnauthed: true,
  isExternalHost: true
};
export const createTracker = (opts = {}) => {
  if (!opts || typeof opts !== 'object') {
    opts = {};
  }

  Object.keys(DEFAULT_OPTS).forEach(key => {
    const value = DEFAULT_OPTS[key];

    if (typeof opts[key] === 'undefined') {
      opts[key] = value;
    }
  });
  return PublicTrackingClient.createTracker(opts);
};
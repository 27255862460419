export const ATTACHMENTS = ['attachments'];
export const AUDIT_PARAMS = ['auditParams'];
export const CHANNEL_INSTANCE_ID = ['channelInstanceId'];
export const CLIENT_TYPE = ['clientType'];
export const CONTENT_TYPE = ['contentType'];
export const ERROR_MESSAGE = ['status', 'sendFailure', 'errorMessage'];
export const GENERIC_CHANNEL_ID = ['genericChannelId'];
export const HAS_MORE = ['hasMore'];
export const ID = ['id'];
export const IN_REPLY_TO_ID = ['inReplyToId'];
export const INTEGRATION_ID = ['integrationId'];
export const LOCALIZED_ERROR_KEY = ['status', 'sendFailure', 'localizedErrorKey'];
export const STRIPPED_ATTACHMENT_COUNT = ['strippedAttachmentCount'];
export const HAS_STRIPPED_INLINE_IMAGES = ['hasInlineImagesStripped'];
export const MESSAGE_DELETE_STATUS = ['messageDeletedStatus'];
export const MESSAGE_ID = ['id'];
export const MESSAGE_SEND_FAILURE = ['status', 'sendFailure'];
export const MESSAGE_STATUS = ['status', 'messageStatus'];
export const MESSAGE_STATUS_SOURCE = ['status', 'source'];
export const MESSAGE_STATUS_TIMESTAMP = ['status', 'timestamp'];
export const RECIPIENTS = ['recipients'];
export const RICH_TEXT = ['richText'];
export const SENDER = ['sender'];
export const SENDER_TYPE = ['sender', '@type'];
export const SENDERS = ['senders'];
export const STATUS = ['status'];
export const TEXT = ['text'];
export const TIMESTAMP = ['timestamp'];
export const TYPE = ['@type'];
export const RECIPIENT_FIELD = ['recipientField'];
export const SENDER_FIELD = ['senderField'];
export const DELIVERY_IDENTIFIER = ['deliveryIdentifier'];
export const DELIVERY_IDENTIFIER_TYPE = ['type'];
export const DELIVERY_IDENTIFIER_VALUE = ['value'];
export const SENDER_DELIVERY_IDENTIFIER_TYPE = ['deliveryIdentifier', 'type'];
export const SENDER_DELIVERY_IDENTIFIER_VALUE = ['deliveryIdentifier', 'value'];
export const ACTOR_ID = ['actorId'];
export const MESSAGE_DIRECTION = ['direction'];
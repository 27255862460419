import { buildErrorMetaObject } from './buildErrorMetaObject';

/**
 * @description Build a error meta action to silence floating error alerts
 * @returns {Object} Meta Action
 *
 * @example
 * const pollThreadListFailed = createAction(
 *   POLL_THREAD_LISTS_FAILED,
 *   error => ({ error }),
 *   silenceErrorAlert
 *  );
 */
export const silenceErrorAlert = ({
  isVisibleToUser = false
} = {}) => buildErrorMetaObject({
  silent: true,
  isVisibleToUser
});
/* hs-eslint ignored failing-rules */

/* eslint-disable no-bitwise */
'use es6'; // eslint-disable-next-line

const GLOBAL_THIS = self;
export const between = (str = '', left = '', right = '') => {
  const leftIndex = str.indexOf(left);
  const rightIndex = str.indexOf(right);
  return str.substr(leftIndex + left.length, rightIndex - leftIndex - right.length);
};
/*
 * Naive approach warning:
 *   An exhaustive debounce function is not needed by the tracker.
 *   Please do not copy + paste this function :)
 */

export const debounce = (fn, wait) => {
  let timeout;
  let result;

  const debounced = (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      result = fn.apply(null, args);
    }, wait);
    return result;
  };

  return debounced;
};
export const defaults = (source = {}, blueprint = {}) => {
  const withDefaults = Object.keys(blueprint).reduce((accumulator, key) => {
    const value = source[key];

    if (value === undefined) {
      accumulator[key] = blueprint[key];
    }

    return accumulator;
  }, {});
  return Object.assign({}, source, {}, withDefaults);
};
export const isArray = thing => {
  if (Array.hasOwnProperty('isArray')) {
    return Array.isArray(thing);
  }

  return Object.prototype.toString.call(thing) === '[object Array]';
};
export const makeUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let v;
    const r = Math.random() * 16 | 0;

    if (c === 'x') {
      v = r;
    } else {
      v = r & 0x3 | 0x8;
    }

    return v.toString(16);
  });
};
export const mapObject = (source = {}, iteratee) => {
  return Object.keys(source).reduce((accumulator, key) => {
    accumulator[key] = iteratee(key, source[key]);
    return accumulator;
  }, {});
};
export const omit = (source = {}, list = [], blacklistMode = true) => {
  return Object.keys(source).reduce((accumulator, key) => {
    const includes = list.indexOf(key) !== -1;
    const shouldOmit = blacklistMode ? includes : !includes;

    if (!shouldOmit) {
      accumulator[key] = source[key];
    }

    return accumulator;
  }, {});
};
export const once = fn => {
  let isCached;
  let result;
  return (...args) => {
    if (!isCached) {
      isCached = true;
      result = fn(...args);
    }

    return result;
  };
};
export const pluck = (subject, collection) => {
  const intMode = isArray(collection);
  const base = intMode ? [] : {};
  return Object.keys(collection).reduce((accumulator, key) => {
    const parsedKey = intMode ? parseInt(key, 10) : key;
    const entry = collection[parsedKey];
    accumulator[parsedKey] = entry[subject];
    return accumulator;
  }, base);
};
export const trim = (str = '', outer = '') => {
  if (typeof str.trim === 'function') {
    str = str.trim();
  } else {
    // IE8 doesn't support String.trim()
    str = str.replace(/^\s+|\s+$/g, '');
  }

  if (str.indexOf(outer) === 0) {
    str = str.substr(outer.length);
  }

  if (str.indexOf(outer) === str.length - outer.length) {
    str = str.substr(0, str.indexOf(outer));
  }

  return str;
};
export const shallowCopy = (source = {}) => Object.keys(source).reduce((accumulator, key) => {
  accumulator[key] = source[key];
  return accumulator;
}, {});
export const truncate = (str = '', limit = 256) => {
  let truncated = str;

  if (truncated.length > limit) {
    truncated = truncated.substr(0, limit);
    truncated = `${truncated}[..]`;
  }

  return truncated;
};
export const resolveAsyncProperties = (properties = {}, onError, callback) => {
  const resolved = {};
  const propertyKeys = Object.keys(properties);
  const propertiesLength = propertyKeys.length;
  const deferred = propertyKeys.reduce((accumulator, key) => {
    let value = properties[key];

    if (value && typeof value === 'function') {
      value = value();

      if (value && typeof value.then === 'function') {
        accumulator.push({
          key,
          promise: value
        });
      } else {
        resolved[key] = value;
      }
    } else {
      resolved[key] = value;
    }

    return accumulator;
  }, []);

  const check = () => {
    if (Object.keys(resolved).length === propertiesLength) {
      callback(resolved);
    }
  };

  if (deferred.length) {
    deferred.forEach(({
      key,
      promise
    }) => {
      promise.then(value => {
        resolved[key] = value;
        check();
      }).catch(err => {
        resolved[key] = undefined;
        onError(err);
        check();
      });
    });
  } else {
    callback(resolved);
  }
};
export const createQueue = () => {
  const queue = [];
  return {
    enqueue: event => queue.unshift(event),
    dequeue: () => queue.shift(),
    peek: () => queue[0]
  };
};
export const safeGetOrDefault = (path = [], def = '', root = GLOBAL_THIS) => {
  return path.reduce((last, cur, index) => {
    const hasNode = last && cur && typeof last[cur] !== 'undefined';
    const isLeaf = index === path.length - 1;

    if (hasNode) {
      return last[cur];
    }

    if (isLeaf) {
      return def;
    }

    return {};
  }, root);
};
export const prettyPrint = (str = '') => {
  const snakeCase = str.replace(/(?:^|\.?)([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, '');
  const titleCase = snakeCase.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  return titleCase.replace(/-/g, ' ').replace(/_/g, ' ').replace(/\s{2}/g, ' ');
};
import enviro from 'enviro';
import Raven from 'Raven';
export const Color = {
  OBSIDIAN: '#33475b',
  OZ: '#00bda5',
  OZ_LIGHT: '#e5f8f6',
  OZ_DARK: '#00a38d',
  CANDY_APPLE_DARK: '#d94c53',
  SLINKY: '#516f90',
  KOALA: '#eaf0f6',
  MARIGOLD: '#f5c26b',
  MARIGOLD_LIGHT: '#fef8f0',
  CANDY_APPLE_LIGHT: '#fdedee',
  CANDY_APPLE: '#f2545b',
  THUNDERDOME: '#6a78d1',
  THUNDERDOME_LIGHT: '#f0f1fa'
};
export default class BaseReporter {
  constructor(options = {}) {
    this.options = options;
    this.debug = enviro.debug('react-rhumb') === 'true';
    this.libName = `react-rhumb`;
  }

  performanceMark(name) {
    if (typeof performance.mark === 'function') {
      performance.mark(name);
    }
  }

  performanceEntries() {
    if (typeof performance.getEntries === 'function') {
      return performance.getEntries() || [];
    }

    return [];
  }

  toDuration(checks, timestamp, markers) {
    const longest = Math.max(...markers.filter(marker => Object.prototype.hasOwnProperty.call(checks, marker)).map(marker => checks[marker].timestamp));
    return Math.max(0, longest - timestamp);
  }

  setCustomAttribute(attributeName, attributeValue) {
    if (window.newrelic) {
      window.newrelic.setCustomAttribute(attributeName, attributeValue);
    }
  }

  addPageAction(actionName, actionPayload) {
    if (window.newrelic) {
      window.newrelic.addPageAction(actionName, actionPayload);
    }
  }

  captureError(error, attributes) {
    let data = {};
    let tags = {};

    if (attributes) {
      data = attributes.data;
      tags = attributes.tags;
    }

    if (window.newrelic) {
      window.newrelic.noticeError(error, Object.assign({}, data, {}, tags));
    }

    Raven.captureException(error, {
      extra: data,
      tags
    });
  }

  report(__action) {
    throw new Error('Reporters must define a custom report() function');
  }

  labelCss(background, border) {
    return `background-color:${background};color:${Color.OBSIDIAN};padding: 0 .5rem;border-left: 4px solid ${border};`;
  }

  colorCss(val) {
    return `color:${val};`;
  }

  logGroupWithBadge(title, lightColor, darkColor, groupContents) {
    console.groupCollapsed(`%c${this.libName}%c ${title}`, this.labelCss(lightColor, darkColor), '');
    groupContents();
    console.groupEnd();
  }

}
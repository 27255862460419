import CartographerReporter from './CartographerReporter';
import performanceNow from '../../vendor/performanceNow';
export default class MemoryReporter extends CartographerReporter {
  constructor(options) {
    super(options);
    this.resolved = {};
    this.stopped = false;
    this.candidate = false; // Only collect measurements in debug mode and for a random 20% of reporter sessions

    if (Math.random() < 0.2 || this.debug) {
      this.candidate = true;
    } // @ts-expect-error lib.dom.ts omits memory from Performance type


    if (!this.candidate || !performance.memory) {
      return;
    }

    const scheduleCollection = () => {
      // collect memory usage with uniform random distribution at least once per minute
      const scheduleNext = Math.random() * 60 * 1000;
      this.timeout = setTimeout(() => {
        // stop collection if Rhumb hits stop conditions or if session runs longer than one hour
        if (this.stopped || performanceNow() > 60 * 60 * 1000) {
          return;
        } // Don't take an immediate sample on load (would skew data). Wait for the first timeout to elapse


        if (this.timeout) {
          this.collectMemoryUsage();
        }

        scheduleCollection();
      }, scheduleNext);
    };

    scheduleCollection(); // Send memory usage performance actions every two minutes

    this.flushingInterval = setInterval(() => {
      if (this.stopped) {
        clearInterval(this.flushingInterval);
      }

      this.flushPerformanceQueue();
    }, 120000);
  }

  collectMemoryUsage() {
    this.pushPerformanceAction(this.lastRouteInfo, 'memory', {
      usedMemBytes: performance.memory.usedJSHeapSize,
      elapsedMs: performanceNow()
    });
  }

  report(action) {
    // @ts-expect-error lib.dom.ts omits memory from Performance type
    if (!this.candidate || !performance.memory) {
      return;
    }

    if (action.type === 'COMPONENT_RENDERED' || this.resolved[action.payload.entry.id] || this.stopped) {
      return;
    }

    switch (action.type) {
      case 'ROUTE_TIMEOUT_EXPIRED':
        {
          const {
            routeSpec: {
              route
            }
          } = action.payload;
          this.lastRouteInfo = {
            route
          };
          break;
        }

      case 'ROUTE_FAILED':
        {
          const {
            entry: {
              pathname
            },
            routeSpec
          } = action.payload;
          const {
            route
          } = routeSpec;
          this.lastRouteInfo = {
            pathname,
            route
          };
          break;
        }

      case 'ROUTE_SUCCEEDED':
        {
          const {
            entry: {
              pathname
            },
            extra: {
              scenario
            },
            routeSpec
          } = action.payload;
          const {
            route
          } = routeSpec;
          this.lastRouteInfo = {
            pathname,
            route,
            scenario
          };
          break;
        }

      default:
    }

    switch (action.type) {
      case 'ROUTE_UNEXPECTED':
        {
          this.stopped = true;
          break;
        }

      case 'ROUTE_SUCCEEDED':
      case 'ROUTE_TIMEOUT_EXPIRED':
      case 'ROUTE_FAILED':
        {
          this.resolved[action.payload.entry.id] = true;
          break;
        }

      default:
    }
  }

}
'use es6';

export const APP_IN_FOREGROUND = 'APP_IN_FOREGROUND';
export const APP_IN_BACKGROUND = 'APP_IN_BACKGROUND';
export const TOGGLE_OPEN = 'TOGGLE_OPEN';
export const ADD_CONVERSATION = 'ADD_CONVERSATION';
export const OPEN_CONVERSATION_FOR_NEW_CHANNEL = 'OPEN_CONVERSATION_FOR_NEW_CHANNEL';
export const RECEIVED_INCOMING_MESSAGE = 'RECEIVED_INCOMING_MESSAGE';
export const CLEAR_CURRENT_CHANNEL = 'CLEAR_CURRENT_CHANNEL';
export const SUBSCRIBE_TO_THREAD_CHANNELS = 'SUBSCRIBE_TO_THREAD_CHANNELS';
export const CLEAR_UNSEEN_COUNT_FOR_CHANNEL = 'CLEAR_UNSEEN_COUNT_FOR_CHANNEL';
export const INCREMENT_UNSEEN_COUNT = 'INCREMENT_UNSEEN_COUNT';
export const ADD_MESSAGE_TO_CONVERSATION = 'ADD_MESSAGE_TO_CONVERSATION';
export const UPDATE_MESSAGE_IN_CONVERSATION = 'UPDATE_MESSAGE_IN_CONVERSATION';
export const NETWORK_OFFLINE = 'NETWORK_OFFLINE';
export const NETWORK_ONLINE = 'NETWORK_ONLINE';
export const GET_WIDGET_DATA = 'GET_WIDGET_DATA';
export const GET_WIDGET_DATA_SUCCEEDED = 'GET_WIDGET_DATA_SUCCEEDED';
export const CLICK_VIRAL_LINK = 'CLICK_VIRAL_LINK';
export const SEND_VISITOR_EMAIL_ADDRESS = 'SEND_VISITOR_EMAIL_ADDRESS';
export const SEND_VISITOR_EMAIL_ADDRESS_FAILED = 'SEND_VISITOR_EMAIL_ADDRESS_FAILED';
export const SEND_VISITOR_EMAIL_ADDRESS_SUCCEEDED = 'SEND_VISITOR_EMAIL_ADDRESS_SUCCEEDED';
export const CLICKED_MEETINGS_LINK = 'CLICKED_MEETINGS_LINK';
export const GET_SUPPLEMENTAL_INITIAL_MESSAGES = 'GET_SUPPLEMENTAL_INITIAL_MESSAGES';
export const GET_SUPPLEMENTAL_INITIAL_MESSAGES_SUCCEEDED = 'GET_SUPPLEMENTAL_INITIAL_MESSAGES_SUCCEEDED';
export const GET_SUPPLEMENTAL_INITIAL_MESSAGES_FAILED = 'GET_SUPPLEMENTAL_INITIAL_MESSAGES_FAILED';
export const GET_VISITOR_THREADS = 'GET_VISITOR_THREADS';
export const GET_VISITOR_THREADS_STARTED = 'GET_VISITOR_THREADS_STARTED';
export const GET_VISITOR_THREADS_SUCCESS = 'GET_VISITOR_THREADS_SUCCESS';
export const GET_VISITOR_THREADS_FAILURE = 'GET_VISITOR_THREADS_FAILURE';
export const LAST_SEEN_SENT = 'LAST_SEEN_SENT';
export const LAST_SEEN_SUCCESS = 'LAST_SEEN_SUCCESS';
export const LAST_SEEN_FAILURE = 'LAST_SEEN_FAILURE';
export const RECEIVED_WIDGET_SHELL_DATA = 'RECEIVED_WIDGET_SHELL_DATA';
export const TRACK_USER_INTERACTION = 'TRACK_USER_INTERACTION';
export const USER_INPUT_ON_FOCUS = 'USER_INPUT_ON_FOCUS';
export const UPDATE_MESSAGE_EDITOR_STAGING_TEXT = 'UPDATE_MESSAGE_EDITOR_STATING_TEXT';
export const REMOVE_MESSAGE_EDITOR_STAGING_TEXT = 'REMOVE_MESSAGE_EDITOR_STAGING_TEXT';
export const THREAD_CREATED_AND_NETWORK_ONLINE = 'THREAD_CREATED_AND_NETWORK_ONLINE';